import { SvgIcon } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import InventoryIcon from "@mui/icons-material/Inventory";
import BackupTableIcon from "@mui/icons-material/BackupTable";

function CardComponent1() {
  return (
    <div className="flex flex-col text-prime-800">
      <div className="text-center px-[60px] my-[30px] ">
        <h1 className="text-3xl mb-[15px]">Vission & Mission</h1>
        <p>
          To be the leading global platform for publishing high-quality,
          peer-reviewed academic and research content, empowering engineers,
          researchers, and scholars to achieve excellence and drive innovation
          in engineering and technology.
        </p>
        <br></br>
        <p>
          Our mission is to provide a comprehensive range of academic and
          research publications that cater to the needs of engineering students,
          research scholars, and professionals. We aim to foster a collaborative
          and inclusive environment where knowledge is created, shared, and
          applied to solve real-world problems. Through rigorous editorial
          standards, innovative digital solutions, and a commitment to
          accessibility, we strive to support the advancement of education and
          research in engineering and technology on a global scale.
        </p>
      </div>
      <div className="text-center py-6">
        <h1 className="text-4xl">Our Commitment</h1>
      </div>
      <div className="flex flex-col lg:flex-row md:flex-col px-[30px]">
        <div className="flex flex-col lg:w-1/3 justify-between border-[1px] rounded-md p-[30px] lg:mx-[20px] md:mx-[10px] mx-[0px] mb-[30px]  shadow-md ">
          <div>
            <SvgIcon
              component={ArticleIcon}
              style={{ fontSize: "60px", marginBottom: "12px" }}
            />
            <h1 className="text-3xl mb-3">High-Quality Academic Publishing</h1>
            <p className="mb-3">
              At Researcher Circle Journals, we are dedicated to publishing
              top-tier academic resources. Our portfolio includes comprehensive
              engineering and technology textbooks, detailed technology-related
              book chapters, and impactful research articles. Each publication
              undergoes rigorous peer review to ensure accuracy, relevance, and
              quality, supporting the academic and professional growth of our
              readers.
            </p>
          </div>
          <div>
            <p className="text-end">
              learn More <SvgIcon component={KeyboardDoubleArrowRightIcon} />
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:w-1/3 justify-between border-[1px] rounded-md p-[30px] lg:mx-[20px] md:mx-[10px] mx-[0px] mb-[30px]  shadow-md ">
          <div>
            <SvgIcon
              component={InventoryIcon}
              style={{ fontSize: "60px", marginBottom: "12px" }}
            />
            <h1 className="text-3xl mb-3">Global Research Community</h1>
            <p className="mb-3">
              We foster a vibrant and collaborative global research community.
              By connecting researchers, scholars, and professionals from
              diverse fields, we enable the exchange of innovative ideas and
              breakthroughs. Our platform provides opportunities for networking,
              collaboration, and knowledge sharing, helping to drive
              advancements in engineering and technology worldwide.
            </p>
          </div>
          <div>
            <p className="text-end">
              learn More <SvgIcon component={KeyboardDoubleArrowRightIcon} />
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:w-1/3 justify-between border-[1px] rounded-md p-[30px] lg:mx-[20px] md:mx-[10px] mx-[0px] mb-[30px]   shadow-md ">
          <div>
            <SvgIcon
              component={BackupTableIcon}
              style={{ fontSize: "60px", marginBottom: "12px" }}
            />
            <h1 className="text-3xl mb-3"> Educational Events and Workshops</h1>
            <p className="mb-3">
              Researcher Circle Journals organizes a variety of educational
              events and workshops aimed at enhancing learning and professional
              development. Our events include seminars, webinars, and
              conferences featuring industry experts and leading academics.
              These interactive sessions provide valuable insights, practical
              skills, and the latest advancements in engineering and technology,
              fostering a culture of continuous learning and innovation.
            </p>
          </div>
          <div>
            <p className="text-end">
              learn More <SvgIcon component={KeyboardDoubleArrowRightIcon} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardComponent1;
