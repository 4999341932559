function Subscribe() {
  return (
    <div className="flex items-center justify-center  w-full lg:p-[80px] p-[30px] text-prime-800">
      <div className="flex flex-col lg:flex-row items-center rounded-lg shadow-md p-[40px] border-prime-800 border-[0.1px] basis-[100%] ">
        <div className="lg:w-[60%] w-full lg:pb-0 pb-[20px] mb-[12px]">
          <h3 className="text-2xl text-gray-500 font-bold">
            Subscribe to our Newsletter
          </h3>
          <p className="text-gray-500">
            Provide your email to get email notification when we publish new
            articles and volumes.
          </p>
        </div>
        <div className="lg:w-[40%] w-full text-center">
          <form>
            <div className="">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email address"
                className="p-[12px] mr-[12px] mb-[12px] text-lg placeholder-prime-800 border border-prime-800 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 "
              />
              <button
                type="submit"
                className="p-[12px] text-white text-lg bg-prime-800 rounded-md whitespace-nowrap ">
                {" "}
                Subscribe{" "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Subscribe;
