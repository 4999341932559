import "./App.css";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import Home from "./pages/Home/Home";
import Books from "./pages/Books/Books";
import Journals from "./pages/Journals/Journals";
import Editors from "./pages/Editors/Editors";
import ContactUs from "./pages/ContactUs/ContactUs";
import Login from "./pages/Login/Login";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import JournalsDetails from "./pages/Journals/JournalsDetails";
import PublicationEthics from "./pages/PublicationEthics/PublicationEthics";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="home" element={<Home />}></Route>
        <Route path="books" element={<Books />}></Route>
        <Route path="Journals" element={<Journals />}>
          <Route path="ComputerScienceandEngineering" element={<JournalsDetails />}></Route>
          <Route path="AgriculturalSciences" element={<JournalsDetails />}></Route>
          <Route path="PharmaceuticalSciences" element={<JournalsDetails />}></Route>
          <Route path="NursingResearch" element={<JournalsDetails />}></Route>
          <Route path="AccountingBusinessFinancialManagement" element={<JournalsDetails />}></Route>
        </Route>
        <Route path="editors" element={<Editors />}></Route>
        <Route path="publication-ethics" element={<PublicationEthics />}></Route>
        <Route path="contactus" element={<ContactUs />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
