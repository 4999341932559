import UnderConstruction from "../UnderConstruction/UnderConstruction";

function Editors() {
  console.log("editors");

  return (
    <>
      <UnderConstruction />
    </>
  );
}

export default Editors;
