import { useState } from "react";
import DeblurIcon from "@mui/icons-material/Deblur";
import SvgIcon from "@mui/icons-material/Deblur";

function PublicationEthics() {
  const data = {
    pageName: "Publication Ethics",
    pageDescription:
      "At Researcher Circle Journals, we are dedicated to maintaining the highest standards of integrity and ethics in our publication process. We adhere to the guidelines and best practices established by the Committee on Publication Ethics (COPE) and other leading organizations in academic publishing. Our commitment to ethical publishing ensures that the integrity of the scholarly record is upheld, fostering trust among authors, reviewers, editors, and readers.",
    content: [
      {
        heading: "Authorship and Contributions",
        subContent: [
          {
            heading: "Authorship Criteria :",
            description:
              "Authorship should be based on significant contributions to the conception, design, execution, or interpretation of the reported study. All individuals who have made substantial contributions should be listed as co-authors. Minor contributors should be acknowledged appropriately.",
          },
          {
            heading: "Responsibilities of Authors :",
            description:
              "Authors are responsible for ensuring that all co-authors have seen and approved the final version of the manuscript and have agreed to its submission for publication. Authors must also ensure that all contributors meet the criteria for authorship and that no deserving co-author is omitted.",
          },
        ],
        order: 1,
      },
      {
        heading: "Originality and Plagiarism",
        subContent: [
          {
            heading: "Original Work :",
            description:
              "Authors must ensure that their submitted work is entirely original and free from plagiarism. Plagiarism, including duplicate publication of the author's own work without proper citation, is unacceptable.",
          },
          {
            heading: "Plagiarism Screening :",
            description:
              "All submitted manuscripts undergo plagiarism screening using advanced detection tools. Manuscripts found to contain significant plagiarism will be rejected, and authors may be subject to sanctions.",
          },
        ],
        order: 2,
      },
      {
        heading: " Data Integrity and Reproducibility",
        subContent: [
          {
            heading: "Accurate Reporting :",
            description:
              "Authors must present an accurate and truthful account of the research conducted and the results obtained. Data should be represented clearly and honestly, without fabrication, falsification, or inappropriate data manipulation.",
          },
          {
            heading: "Data Availability :",
            description:
              "Authors may be required to provide raw data related to their manuscript for editorial review and must be prepared to make the data publicly available if feasible. This transparency ensures the reproducibility of research findings.",
          },
        ],
        order: 2,
      },
      {
        heading: "Conflict of Interest",
        subContent: [
          {
            heading: "Disclosure Requirements :",
            description:
              "Authors, reviewers, and editors must disclose any financial or personal relationships that could influence their work. Conflicts of interest must be declared in the manuscript and managed appropriately to maintain transparency and trust.",
          },
          {
            heading: "Avoiding Bias :",
            description:
              "Reviewers and editors with conflicts of interest should recuse themselves from handling manuscripts where such conflicts could bias their judgment.",
          },
        ],
        order: 3,
      },
      {
        heading: "Peer Review Process",
        subContent: [
          {
            heading: "Confidentiality :",
            description:
              "Manuscripts received for review are treated as confidential documents. Reviewers must not disclose or discuss the manuscript with others except as authorized by the editor.",
          },
          {
            heading: "Constructive Feedback :",
            description:
              "Reviewers are expected to provide objective, constructive, and timely feedback to improve the quality of the manuscript. Personal criticism of the authors is not permitted.",
          },
        ],
        order: 4,
      },
      {
        heading: "Ethical Oversight",
        subContent: [
          {
            heading: "Ethical Approval :",
            description:
              "Research involving human participants, animals, or hazardous materials must comply with relevant ethical standards and obtain necessary approvals from institutional review boards or ethics committees.",
          },
          {
            heading: "Informed Consent :",
            description:
              "Authors must ensure that informed consent was obtained from all human participants involved in the research. The privacy rights of participants must always be observed.",
          },
        ],
        order: 6,
      },
      {
        heading: "Handling Misconduct",
        subContent: [
          {
            heading: "Investigating Misconduct :",
            description:
              "Allegations of research or publication misconduct, such as fabrication, falsification, plagiarism, or unethical behavior, will be investigated thoroughly. Researcher Circle Journals will follow COPE guidelines to handle such allegations fairly and transparently.",
          },
          {
            heading: "Correcting the Record :",
            description:
              "If misconduct is proven, appropriate actions will be taken, which may include the retraction or correction of published articles. Authors will be given the opportunity to respond to allegations, and all parties involved will be treated fairly.",
          },
        ],
        order: 7,
      },
      {
        heading: "Retraction and Correction Policies",
        subContent: [
          {
            heading: "Retractions :",
            description:
              "Articles that are found to contain significant errors or ethical violations may be retracted. Retractions will be clearly indicated and linked to the original article to maintain the integrity of the scholarly record.",
          },
          {
            heading: "Corrections :",
            description:
              "Minor errors that do not affect the overall results and conclusions of the paper will be corrected through formal errata or corrigenda, which will be linked to the original publication.",
          },
        ],
        order: 8,
      },
      {
        heading: "Maintaining Transparency",
        subContent: [
          {
            heading: "Open Access and Transparency :",
            description:
              "We are committed to maintaining transparency in our editorial processes and decision-making. Our peer review process, editorial policies, and conflict of interest statements are openly accessible to promote trust and accountability.",
          },
          {
            heading: "Publication Fees :",
            description:
              "Any fees associated with the publication process, such as article processing charges (APCs), will be clearly disclosed on our website to avoid any hidden costs.",
          },
        ],
        order: 9,
      },
      {
        heading: "",
        subContent: [
          {
            heading: "",
            description:
              "By adhering to these ethical principles, Researcher Circle Journals aims to uphold the highest standards of academic integrity, fostering a culture of ethical research and publication practices. We believe that maintaining these standards is crucial for advancing knowledge and ensuring the trustworthiness of scholarly communication in engineering and technology.",
          },
        ],
        order: 10,
      },
      {
        heading: "",
        subContent: [
          {
            heading: "",
            description:
              "For any questions or concerns regarding our publication ethics, please contact us at contact@researchercircle.com.",
          },
        ],
        order: 11,
      },
    ],
  };

  const [pageData, setPageData] = useState(data);

  return (
    <div className="text-prime-800">
      {pageData && (
        <div>
          <div className="text-center py-[80px] bg-prime-400 ">
            <div className="flex items-center justify-center">
              <div className="">
                <SvgIcon
                  component={DeblurIcon}
                  style={{ fontSize: "50px" }}></SvgIcon>
              </div>
            </div>
            <h2 className="text-4xl">{pageData.pageName}</h2>
            <div className="px-40 pt-[10px]">
              <p>{pageData.pageDescription}</p>
            </div>
          </div>
          <div className="pb-10">
            {pageData.content.map((content: any,index:number) => (
              <div className="px-40 pt-4">
                <h1 className="text-3xl font-medium py-[10px]">
                  {index+1}.&nbsp;{content.heading}
                </h1>
                {content.subContent.map((sc: any) => (
                  <div className="pb-[10px] text-justify">
                    {/* <h1 className="text-xl font-medium"></h1> */}
                    <p className="pl-[30px]">
                      <span className="font-medium text-lg">{sc.heading} </span>
                      {sc.description}
                    </p>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicationEthics;
