import { SvgIcon } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useEffect, useRef, useState } from "react";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { journaldetails } from "../../pages/Journals/Journals.config";
function CardComponent3() {
  const setImage = (cardData: any) => {
    const data = cardData;
    cardData.map((data: any) => {
      import(`../../assets/${data.cover}`).then((image) => {
        const img = image.default;
        data.cover = img;
      });
    });
    return data;
  };
  const [journalsData, setJournalsData] = useState<any>(journaldetails);

  const [deviceWidth, setDeviceWidth] = useState(0);
  const ref = useRef(null);
  const scroll = (reff: any, scrollOffset: any) => {
    if (reff != null) {
      reff.current.scrollLeft += scrollOffset;
    }
  };
  useEffect(() => {
    if (window.innerWidth < 769) {
      setDeviceWidth(280);
    } else {
      setDeviceWidth(630);
    }
  });

  useEffect(() => {
    console.log(journalsData);
  }, [journalsData]);

  return (
    <>
      <div className="flex flex-col px-[50px] text-prime-800 py-[50px]">
        <div className="text-center px-[60px] ">
          <h1 className="text-3xl mb-[15px]">RC Journals</h1>
        </div>
        <div className="flex justify-end pb-[15px]">
          <button
            onClick={() => {
              scroll(ref, -deviceWidth);
            }}
            className="bg-prime-800 text-prime-100 rounded-full mr-[15px]">
            <SvgIcon
              component={NavigateBeforeIcon}
              style={{ fontSize: "30px" }}
            />
          </button>
          <button
            onClick={() => scroll(ref, deviceWidth)}
            className="bg-prime-800 text-prime-100 rounded-full">
            <SvgIcon
              component={NavigateNextIcon}
              style={{ fontSize: "30px" }}
            />
          </button>
        </div>
        <div
          className="flex overflow-y-scroll scroll-smooth hide-scrollbar-style"
          ref={ref}>
          {journalsData &&
            journalsData.map((journal: any, index: number) => (
              <div key={index}>
                <div className="flex py-[15px] pr-[30px] items-start lg:flex-row flex-col h-full">
                  <div className="flex w-[250px] lg:w-[300px] bg-prime-800 rounded-md border-[2px] border-prime-800 shadow-lg">
                    <img src={journal.cover} className="rounded-md" />
                  </div>
                  <div className="lg:pl-[30px] w-[250px] lg:w-[350px] self-stretch pt-[20px] lg:pt-0">
                    <div className="flex flex-col justify-between h-full">
                      <div>
                        <p className="">RC International Journal of</p>
                        <h1 className="text-2xl font-bold">{journal.name}</h1>
                        <p className="mt-[30px] pl-[30px] border-l-[2px]">
                          {journal.description}
                        </p>
                      </div>
                      <div className="flex pt-[20px] lg:pt-0">
                        <a
                          href={journal.navLink}
                          className="px-[15px] py-[8px] border-1 rounded-md  bg-prime-800 text-prime-100">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default CardComponent3;
