import { SvgIcon } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import logo from "../../assets/logo/RCLogoSquare.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
function Footer() {
  const navigate = useNavigate();
  const pagesObj = [
    {
      name: "Terms & conditions",
      navLink: "contactus",
      id: 4,
      showInFooter: true,
    },
    {
      name: "Home",
      navLink: "home",
      id: 1,
      showSubNav: false,
      showInFooter: true,
    },
    {
      name: "Journals",
      navLink: "journals",
      subNav: [
        {
          name: "Computer Science and Engineering",
          navLink: "journals",
          id: "RCI0001",
        },
        {
          name: "Agricultural Sciences",
          navLink: "journals",
          id: "RCI0002",
        },
        {
          name: "Pharmaceutical Sciences",
          navLink: "journals",
          id: "RCI0003",
        },
        {
          name: "Nursing Research",
          navLink: "journals",
          id: "RCI0004",
        },
        {
          name: "Accounting, Business and Financial Management",
          navLink: "journals",
          id: "RCI0005",
        },
      ],
      showSubNav: false,
      id: 2,
      showInFooter: true,
    },
    {
      name: "Publication Ethics",
      navLink: "editors",
      showSubNav: false,
      id: 3,
      showInFooter: false,
    },
    { name: "Contact Us", navLink: "contactus", id: 4, showInFooter: false },
  ];
  const [pages, setPages] = React.useState(pagesObj);

  return (
    <>
      <div className="border-t-[2px] text-prime-800 bg-prime-100">
        <div className="flex lg:flex-row flex-col py-[30px] px-[20px]">
          <div className="flex flex-col lg:w-[40%] items-center ">
            <div className="flex flex-col items-center text-center">
              <img
                src={logo}
                alt="Researcher Circle"
                onClick={() => {
                  navigate("/home");
                }}
                className="cursor-pointer w-[300px]"
              />
              <p className="py-[15px] text-[14px]">
              Empowering scholarly excellence in engineering and technology through rigorous publishing and global collaboration. Your gateway to cutting-edge research and educational resources.
              </p>
            </div>
            <div>
              <a href="/">
                <SvgIcon
                  component={LinkedInIcon}
                  className="ml-[15px] cursor-pointer"></SvgIcon>
              </a>
              <a href="/">
                <SvgIcon
                  component={GoogleIcon}
                  className="ml-[15px] cursor-pointer"></SvgIcon>
              </a>
              <a href="/">
                <SvgIcon
                  component={MailIcon}
                  className="ml-[15px] cursor-pointer"></SvgIcon>
              </a>
              <a href="/">
                <SvgIcon
                  component={WhatsAppIcon}
                  className="ml-[15px] cursor-pointer"></SvgIcon>
              </a>
              <a href="/">
                <SvgIcon
                  component={FacebookIcon}
                  className="ml-[15px] cursor-pointer"></SvgIcon>
              </a>
            </div>
          </div>
          <div className="flex lg:w-[60%] flex-col  self-center">
            <div className="self-center text-2xl py-[20px] ">Contact Us</div>
            <div className="flex lg:flex-row flex-col justify-around">
              <div className="items-center flex flex-col pb-[25px]">
                <SvgIcon
                  component={LocationOnIcon}
                  style={{ fontSize: "35px" }}></SvgIcon>
                <h2 className="text-[15px] font-bold">Postal Address</h2>
                <div className="text-center pt-[5px]">
                  <p className="text-[14px]">
                    Researcher Circle Pvt. Ltd. <br></br>no. 443, 1st Floor,{" "}
                    <br></br>2nd stage Bharath Nagar,<br></br> Bangalore -
                    560091
                  </p>
                </div>
              </div>
              <div className="items-center flex flex-col  pb-[25px]">
                <SvgIcon
                  component={PhoneIcon}
                  style={{ fontSize: "35px" }}></SvgIcon>
                <h2 className="text-[15px] font-bold">Phone Number</h2>
                <div className="text-center pt-[5px]">
                  <p className="text-[14px]">
                    +91 8971896965 <br></br>+91 7676296965
                  </p>
                </div>
              </div>
              <div className="items-center flex flex-col  pb-[25px]">
                <SvgIcon
                  component={EmailIcon}
                  style={{ fontSize: "35px" }}></SvgIcon>
                <h2 className="text-[15px] font-bold">Email Address</h2>
                <div className="text-center pt-[5px]">
                  <p className="text-[14px]">
                    contact@researchercircle.com<br></br>
                    researchercircle@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex text-[14px] lg:justify-between justify-center p-[20px] items-center border-t-[0.5px]">
          <div className="lg:flex hidden items-center ">
            <span className="pr-[5px]">
              <SvgIcon
                component={CopyrightIcon}
                style={{ width: "16px" }}></SvgIcon>{" "}
            </span>
            2024, Copyright - Researcher Circle Pvt. Ltd. All Rights Reserved.
          </div>
          <div className="lg:hidden flex items-start  justify-center text-center">
            <span className="pr-[5px]">
              <SvgIcon
                component={CopyrightIcon}
                style={{ width: "16px" }}></SvgIcon>{" "}
            </span>
            2024, Copyright - Researcher Circle Pvt. Ltd.<br></br>All Rights Reserved.
          </div>
          <div className="lg:flex  hidden ">
            {pages.map((nav,index) => (
              <div key={String(nav.id)+String(index)}>
                {nav.showInFooter && (
                  <div
                    onClick={() => {
                      navigate(nav.navLink);
                    }}
                    className="px-[10px] cursor-pointer">
                    {nav.name}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
