import { SvgIcon } from "@mui/material";
import UnderConstruction from "../UnderConstruction/UnderConstruction";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import GoogleIcon from "@mui/icons-material/Google";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Login() {
  console.log("login");
  const [showLogin, setshowLogin] = React.useState(true);
  const [showRegistration, setshowRegistration] = React.useState(false);
  const [showGoogleReg, setshowGoogleReg] = React.useState(false);

  return (
    <>
      {showLogin && (
        <div className="text-prime-800">
          <div className="text-center  py-[80px] bg-prime-400">
            <div className="flex items-center justify-center">
              <div className="text-gray-500">
                <SvgIcon
                  component={LockPersonIcon}
                  style={{ fontSize: "50px" }}></SvgIcon>
              </div>
            </div>
            <h2 className="text-4xl tracking-tight text-gray-500">
              Sign in into your account
            </h2>
            <span className="text-sm">
              or{" "}
              <label
                className="text-blue-500 font-semibold cursor-pointer"
                onClick={() => {
                  setshowRegistration(true);
                  setshowLogin(false);
                }}>
                register a new account
              </label>
            </span>
          </div>
          <div className="flex justify-center mb-[40px] mt-[30px] mx-4 md:mx-0">
            <form className="w-full max-w-xl bg-white rounded-lg shadow-md p-6 border-gray-300 border-[0.1px]">
              <div className="flex flex-wrap mb-6">
                <div className="flex items-center w-full ">
                  <div className="w-full px-3 pt-4 mx-2 ">
                    <button className="appearance-none flex items-center justify-center w-full bg-gray-100 text-gray-700 shadow border border-gray-500 rounded-lg py-3 px-3 leading-tight hover:bg-gray-200 hover:text-gray-700 focus:outline-none">
                      <SvgIcon
                        component={GoogleIcon}
                        className="h-6 fill-current text-gray-700"></SvgIcon>
                      &nbsp; Login with Google
                    </button>
                  </div>
                </div>
                <div className="mx-auto py-[15px]">
                  <span className="text-center text-s text-gray-700">or</span>
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Email address
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                    type="email"
                    required
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                    type="password"
                    required
                  />
                </div>
                <div className="w-full flex items-center justify-between px-3 mb-3 ">
                  <label className="flex items-center w-1/2">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="mr-1 bg-white shadow"
                    />
                    <span className="text-sm text-gray-700 ">Remember Me</span>
                  </label>
                  <div className="w-1/2 text-right">
                    <a
                      href="#"
                      className="text-blue-500 text-sm tracking-tight">
                      Forget your password?
                    </a>
                  </div>
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <button className="appearance-none block w-full bg-blue-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none focus:bg-white focus:border-gray-500">
                    Sign in
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {showRegistration && (
        <div className="text-prime-800">
          <div className="text-center  py-[80px] bg-prime-400">
            <div className="flex items-center justify-center">
              <div className="text-gray-500">
                <SvgIcon
                  component={AccountCircleIcon}
                  style={{ fontSize: "50px" }}></SvgIcon>
              </div>
            </div>
            <h2 className="text-4xl tracking-tight text-gray-500">
            Create New Account
            </h2>
            <span className="text-sm">
              or{" "}
              <label
                className="text-blue-500 font-semibold cursor-pointer"
                onClick={() => {
                  setshowLogin(true);
                  setshowRegistration(false);
                }}>
                 login to your account
              </label>
            </span>
          </div>
          <div className="flex justify-center mb-[40px] mt-[30px] mx-4 md:mx-0">
            <form className="w-full max-w-xl bg-white rounded-lg shadow-md p-6 border-gray-300 border-[0.1px]">
              <div className="flex flex-wrap mb-6">
              <div className="w-full md:w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                    type="email"
                    required
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Email address
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                    type="email"
                    required
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                    type="password"
                    required
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                    Confirm Password
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                    type="password"
                    required
                  />
                </div>
                <div className="w-full md:w-full px-3 mb-6">
                  <button className="appearance-none block w-full bg-blue-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none focus:bg-white focus:border-gray-500">
                  Register
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
