import CardComponent1 from "../../components/cardComponent1/cardComponent1";
import CardComponent2 from "../../components/cardComponent2/cardComponent2";
import CardComponent3 from "../../components/cardComponent3/cardComponent3";
import InfoComponent1 from "../../components/infoComponent1/infoComponent1";
import Subscribe from "../../components/subscribe/subscribe";
import UnderConstruction from "../UnderConstruction/UnderConstruction";

function Home() {
  console.log("home");

  return (
    <>
      <div className="bg-prime-100">
        <InfoComponent1 />
      </div>
      <div className="bg-prime-200">
        <CardComponent1 />
      </div>
      <div className="bg-prime-300">
        <CardComponent3 />
      </div>
      <div className="bg-prime-200">
        <Subscribe />
      </div>
    </>
  );
}

export default Home;
