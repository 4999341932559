import CSE from "../../assets/bookCovers/CSE.jpg";
import ABFM from "../../assets/bookCovers/ABFM.jpg";
import AS from "../../assets/bookCovers/AS.jpg";
import NR from "../../assets/bookCovers/NR.jpg";
import PS from "../../assets/bookCovers/PS.jpg";
export interface IJournal {
  name: string;
  navLink: string;
  id: string;
  cover: string;
  description: string;
  title: string;
  frequency: string;
  eISSN: string;
  publisher: string;
  chiefEditor: string;
  copyright: string;
  startingYear: string;
  subject: string;
  language: string;
  publicationFormat: string;
  emailId: string;
  mobileNo: string;
  readership: string;
  indexedIn: string;
}
export const journaldetails:IJournal[] = [
  {
    name: "Computer Science and Engineering",
    navLink: "Journals/ComputerScienceandEngineering",
    id: "RCI0001",
    cover: CSE,
    description:
      "A leading peer-reviewed publication dedicated to advancing the fields of computer science and engineering. Our journal serves as a platform for researchers, academics, and professionals to share innovative research, cutting-edge technologies, and comprehensive reviews, fostering progress and excellence in the global tech community.",
    title: "RC International Journal of Computer Science and Engineering",
    frequency: "Triannual (April, August, December)",
    eISSN: "",
    publisher: "Researcher Circle Pvt. Ltd.",
    chiefEditor: "Dr. D P Gaikwad",
    copyright: "© Researcher Circle Pvt. Ltd.",
    startingYear: "2024",
    subject: "Computer Science & Engineering",
    language: "English",
    publicationFormat: "Online",
    emailId: "contact@researchercircle.com",
    mobileNo: "+91 7676296965 / +91 8971896965",
    readership: "Professors, Research Scholars, Students, Engineers",
    indexedIn:
      "Google Scholar, Scientific Journal Impact Factor (SJIF2018: 5.685), Crossref, Figshare, Zenodo, DOI",
  },
  {
    name: "Agricultural Sciences",
    navLink: "Journals/AgriculturalSciences",
    id: "RCI0002",
    cover: AS,
    description:
      "A renowned peer-reviewed journal committed to the dissemination of pioneering agricultural research. We provide a global platform for scholars and experts to publish innovative research, sustainable techniques, and comprehensive reviews, driving forward the science and practice of agriculture.",
    title: "RC International Journal of Agricultural Sciences",
    frequency: "Triannual (April, August, December)",
    eISSN: "",
    publisher: "Researcher Circle Pvt. Ltd.",
    chiefEditor: "Dr. D P Gaikwad",
    copyright: "© Researcher Circle Pvt. Ltd.",
    startingYear: "2024",
    subject: "Agricultural Sciences",
    language: "English",
    publicationFormat: "Online",
    emailId: "contact@researchercircle.com",
    mobileNo: "+91 7676296965 / +91 8971896965",
    readership: "Professors, Research Scholars, Students, Engineers",
    indexedIn:
      "Google Scholar, Scientific Journal Impact Factor (SJIF2018: 5.685), Crossref, Figshare, Zenodo, DOI",
  },
  {
    name: "Pharmaceutical Sciences",
    navLink: "Journals/PharmaceuticalSciences",
    id: "RCI0003",
    cover: PS,
    description:
      "A distinguished peer-reviewed publication dedicated to advancing the field of pharmaceutical sciences. Our journal serves as a platform for researchers, scientists, and professionals to share groundbreaking research, innovative methodologies, and critical reviews, fostering progress and excellence in pharmaceutical research and development globally.",
    title: "RC International Journal of Pharmaceutical Sciences",
    frequency: "Triannual (April, August, December)",
    eISSN: "",
    publisher: "Researcher Circle Pvt. Ltd.",
    chiefEditor: "Dr. D P Gaikwad",
    copyright: "© Researcher Circle Pvt. Ltd.",
    startingYear: "2024",
    subject: "Pharmaceutical Sciences",
    language: "English",
    publicationFormat: "Online",
    emailId: "contact@researchercircle.com",
    mobileNo: "+91 7676296965 / +91 8971896965",
    readership: "Professors, Research Scholars, Students, Engineers",
    indexedIn:
      "Google Scholar, Scientific Journal Impact Factor (SJIF2018: 5.685), Crossref, Figshare, Zenodo, DOI",
  },
  {
    name: "Nursing Research",
    navLink: "Journals/NursingResearch",
    id: "RCI0004",
    cover: NR,
    description:
      "A leading peer-reviewed publication dedicated to advancing nursing through high-quality research and scholarly articles. We provide a platform for nursing professionals to share innovative practices and evidence-based research, contributing to the enhancement of patient care and the nursing profession globally.",
    title: "RC International Journal of Nursing Research",
    frequency: "Triannual (April, August, December)",
    eISSN: "",
    publisher: "Researcher Circle Pvt. Ltd.",
    chiefEditor: "Dr. D P Gaikwad",
    copyright: "© Researcher Circle Pvt. Ltd.",
    startingYear: "2024",
    subject: "Nursing Research",
    language: "English",
    publicationFormat: "Online",
    emailId: "contact@researchercircle.com",
    mobileNo: "+91 7676296965 / +91 8971896965",
    readership: "Professors, Research Scholars, Students, Engineers",
    indexedIn:
      "Google Scholar, Scientific Journal Impact Factor (SJIF2018: 5.685), Crossref, Figshare, Zenodo, DOI",
  },
  {
    name: "Accounting, Business and Financial Management",
    navLink: "Journals/AccountingBusinessFinancialManagement",
    id: "RCI0005",
    cover: ABFM,
    description:
      "A premier peer-reviewed journal focused on the latest research and developments in accounting, business, and finance. Our journal offers a valuable resource for scholars, practitioners, and policymakers to explore innovative solutions, best practices, and comprehensive analyses, driving excellence and progress in the global business landscape.",
    title:
      "RC International Journal of Accounting, Business and Financial Management",
    frequency: "Triannual (April, August, December)",
    eISSN: "",
    publisher: "Researcher Circle Pvt. Ltd.",
    chiefEditor: "Dr. D P Gaikwad",
    copyright: "© Researcher Circle Pvt. Ltd.",
    startingYear: "2024",
    subject: "Accounting, Business and Financial Management",
    language: "English",
    publicationFormat: "Online",
    emailId: "contact@researchercircle.com",
    mobileNo: "+91 7676296965 / +91 8971896965",
    readership: "Professors, Research Scholars, Students, Engineers",
    indexedIn:
      "Google Scholar, Scientific Journal Impact Factor (SJIF2018: 5.685), Crossref, Figshare, Zenodo, DOI",
  },
];
