import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { SvgIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";

function InfoComponent1() {
  const navigate = useNavigate();

  return (
    <div className="flex items-center flex-col lg:flex-row w-full lg:px-[30px] px-[10px] lg:pb-[60px] py-[30px] text-prime-800">
      <div className="flex lg:basis-[50%] basis-[100%] justify-center lg:p-[20px] md:p-[20px] p-0 relative w-full">
        <div className="flex basis-[100%]">
          <div className="flex basis-[50%] h-[340px] bg-prime-700 rounded-lg m-[10px]"></div>
          <div className="flex basis-[50%] h-[200px] bg-prime-500 rounded-lg m-[15px]"></div>
        </div>
        <div className="flex absolute bottom-[-50px] md:bottom-[-30px] lg:bottom-[-30px] items-center bg-prime-100 rounded-xl">
          <div className="w-[40%] m-[10px] bg-prime-400 p-[30px] text-black rounded-xl">
            <h1 className="font-bold text-[30px]">50+</h1>
            <p>experienced editors</p>
          </div>
          <div className="list-disc w-[60%] text-gray bg-prime-100 rounded-xl">
            <p>
              <SvgIcon component={ArrowForwardIosIcon} />
              latest Trends
            </p>
            <p>
              <SvgIcon component={ArrowForwardIosIcon} />
              Best Content
            </p>
            <p>
              <SvgIcon component={ArrowForwardIosIcon} />4 quarter per year
            </p>
            <p>
              <SvgIcon component={ArrowForwardIosIcon} />
              All domain
            </p>
          </div>
        </div>
      </div>
      <div className="flex lg:basis-[50%] basis-[100%] justify-start flex-col pt-[70px] pb-[40px] px-[50px] ">
        <h1 className="text-3xl">
        Welcome to Researcher Circle Journals
        </h1>
        <p className="text-m py-[20px]">
          Researcher Circle Journals (RC Journals) is your premier destination
          for cutting-edge academic publications in engineering, technology, and
          more. Our platform is dedicated to supporting the scholarly community
          with high-quality textbooks, comprehensive technology-related book
          chapters, and impactful research articles. Whether you are a student,
          a research scholar, or a professional engineer, RC Journals provides
          the resources you need to advance your knowledge and contribute to the
          global scientific community. Explore our extensive collection of
          peer-reviewed publications, join our network of experts, and stay at
          the forefront of innovation and academic excellence. Discover more
          with Researcher Circle Journals today!
        </p>
        <div className="w-full flex justify-end">
        <button
          className="w-fit p-[10px] rounded bg-prime-800 text-white"
          onClick={() => {
            navigate("/contactus");
          }}>
          Contact Us
        </button>
        </div>
        
      </div>
    </div>
  );
}

export default InfoComponent1;
