import { SvgIcon } from "@mui/material";
import ContactForm from "../../components/contactForm/contactForm";
import ContactsSharpIcon from "@mui/icons-material/ContactsSharp";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MailIcon from "@mui/icons-material/Mail";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
function ContactUs() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="text-prime-800">
      <div className="text-center py-[80px] bg-prime-400 ">
        <div className="flex items-center justify-center">
          <div className="">
            <SvgIcon
              component={ContactsSharpIcon}
              style={{ fontSize: "50px" }}></SvgIcon>
          </div>
        </div>
        <h2 className="text-4xl">Contact Us</h2>
      </div>
      <div className="flex justify-center items-center pt-[10px] lg:flex-row flex-col">
        <div className="">
          <div className="flex flex-col justify-around  mt-[30px] mx-4 px-4">
            <div className="pb-[30px]">
              <h2>
                <span className="text-3xl">Reach Us</span>
                <span>&nbsp;through</span>
              </h2>
            </div>
            <div className="items-center flex flex-row pb-[25px]">
              <div className="flex">
                <SvgIcon
                  component={LocationOnIcon}
                  style={{ fontSize: "60px" }}></SvgIcon>
              </div>
              <div className="text-start pt-[5px] pl-[15px]">
                <h2 className="text-[25px] font-bold">Postal Address</h2>
                <p className="text-[16px]">
                  Researcher Circle Pvt. Ltd. <br></br>no. 443, 1st Floor, 2nd
                  stage Bharath Nagar, Bangalore - 560091
                </p>
              </div>
            </div>

            <div className="items-center flex flex-row pb-[25px]">
              <div className="flex">
                <SvgIcon
                  component={PhoneIcon}
                  style={{ fontSize: "60px" }}></SvgIcon>
              </div>
              <div className="text-start pt-[5px] pl-[15px]">
                <h2 className="text-[25px] font-bold">Phone Number</h2>
                <p className="text-[16px]">
                  +91 8123498989, +91 8123498989, +91 8123498989
                </p>
              </div>
            </div>

            <div className="items-center flex flex-row pb-[25px]">
              <div className="flex">
                <SvgIcon
                  component={EmailIcon}
                  style={{ fontSize: "60px" }}></SvgIcon>
              </div>
              <div className="text-start pt-[5px] pl-[15px]">
                <h2 className="text-[25px] font-bold">Email Address</h2>
                <p className="text-[16px]">
                  contact@researchercircle.com<br></br>
                  researchercircle@gmail.com
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col mx-4 px-4 ">
            <h1 className="text-3xl pb-[20px]">Social Handels</h1>
            <a href="/">
              <SvgIcon
                component={LinkedInIcon}
                className="ml-[15px] cursor-pointer"></SvgIcon>&nbsp; Linkedin : researchercircle
            </a>
            <a href="/">
              <SvgIcon
                component={GoogleIcon}
                className="ml-[15px] cursor-pointer"></SvgIcon>&nbsp; Google : researchercircle
            </a>
            <a href="/">
              <SvgIcon
                component={MailIcon}
                className="ml-[15px] cursor-pointer"></SvgIcon>&nbsp; Gmail : researchercircle
            </a>
            <a href="/">
              <SvgIcon
                component={WhatsAppIcon}
                className="ml-[15px] cursor-pointer"></SvgIcon>&nbsp; WhatsApp : researchercircle
            </a>
            <a href="/">
              <SvgIcon
                component={FacebookIcon}
                className="ml-[15px] cursor-pointer"></SvgIcon>&nbsp; Facebook : researchercircle
            </a>
          </div>
        </div>
        <div className="lg:mx-4 lg:px-4 mx-0">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
