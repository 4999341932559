import './UnderConstruction.scss';
import SvgIcon from "@mui/material/SvgIcon";
import HandymanIcon from "@mui/icons-material/Handyman";

function UnderConstruction() {

    return (
        <>
            <div className="App">
                <p>Researcher Circle</p>
                <SvgIcon className="icon" component={HandymanIcon} />
                <div>website under development</div>
            </div>
        </>
    );
}

export default UnderConstruction;