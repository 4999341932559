import { Outlet, useLocation } from "react-router-dom";
import UnderConstruction from "../UnderConstruction/UnderConstruction";

function Journals() {
  const location = useLocation();
  return (
    <>
      <Outlet context={location.pathname.split("/")[2]}></Outlet>
    </>
  );
}

export default Journals;
