import "./header.scss";
import logo1 from "../../assets/logo/RCLogo1.png";
import SvgIcon from "@mui/material/SvgIcon";
import { Filter, Login } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
function Header() {
  const pagesObj = [
    { name: "Home", navLink: "home", id: 1 },
    {
      name: "Journals",
      navLink: "journals",
      subNav: [
        {
          name: "Computer Science and Engineering",
          navLink: "Journals/ComputerScienceandEngineering",
          id: "RCI0001",
        },
        {
          name: "Agricultural Sciences",
          navLink: "Journals/AgriculturalSciences",
          id: "RCI0002",
        },
        {
          name: "Pharmaceutical Sciences",
          navLink: "Journals/PharmaceuticalSciences",
          id: "RCI0003",
        },
        {
          name: "Nursing Research",
          navLink: "Journals/NursingResearch",
          id: "RCI0004",
        },
        {
          name: "Accounting, Business and Financial Management",
          navLink: "Journals/AccountingBusinessFinancialManagement",
          id: "RCI0005",
        },
      ],
      showSubNav: false,
      id: 2,
    },
    {
      name: "Publication Ethics",
      navLink: "publication-ethics",
      showSubNav: false,
      id: 3,
    },
    { name: "Contact Us", navLink: "contactus", id: 4 },
  ];
  const navigate = useNavigate();
  const [pages, setPages] = React.useState(pagesObj);
  const [showMobileNav, setshowMobileNav] = React.useState(false);
  const showHideSubNavigation: any = (page: any) => {
    const pagesObjs = pages.map((c, i) => {
      if (page.id === c.id) {
        c.showSubNav = !c.showSubNav;
        return c;
      } else {
        c.showSubNav = false;
        return c;
      }
    });
    setPages(pagesObjs);
  };

  const hideAllNavigation: any = () => {
    const pagesObj = pages.map((c, i) => {
      c.showSubNav = false;
      return c;
    });
    setPages(pagesObj);
  };

  return (
    <>
      <div className="header flex justify-between py-[15px] items-center border-b-[1px] border-prime-800 bg-prime-200 text-prime-800 font-normal">
        <div className="flex">
          <div className="lg:hidden flex items-center px-[15px] relative">
            <div
              onClick={() => {
                setshowMobileNav(!showMobileNav);
                hideAllNavigation();
              }}
            >
              <SvgIcon component={MenuIcon}></SvgIcon>
            </div>
            {showMobileNav && (
              <div className="absolute top-[50px] bg-prime-200  border-prime-800 border-[0.1px] rounded shadow-md z-10 px-[10px] w-max max-w-[300px]">
                {pages.map((page, i) => (
                  <div
                    key={page.name}
                    onClick={() => {
                      if (!page.subNav) {
                        navigate(page.navLink);
                        setshowMobileNav(!showMobileNav);
                        hideAllNavigation();
                      } else {
                        showHideSubNavigation(page);
                      }
                    }}
                    className="navLinks px-[10px]"
                  >
                    <div className="cursor-pointer flex py-[10px] ">
                      {page.name}
                      {page.subNav && (
                        <SvgIcon
                          className=""
                          component={KeyboardArrowDownIcon}
                          onClick={() => {}}
                        />
                      )}
                    </div>
                    {page.showSubNav && (
                      <div className="min-w-[170px] bg-white px-[15px]">
                        {page.subNav?.map((nav, index) => (
                          <>
                            <div
                              className="flex justify-between py-[10px] cursor-pointer align-middle"
                              key={nav.id}
                              onClick={() => {
                                navigate(nav.navLink);
                                hideAllNavigation();
                                setshowMobileNav(false);
                              }}
                            >
                              {nav.name}
                              <SvgIcon
                                className="ml-[15px]"
                                component={ArrowRightAltIcon}
                              />
                            </div>
                            {index < page.subNav.length - 1 && (
                              <div className="border-prime-800 border-b-[1px]"></div>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                    {i < pages.length - 1 && (
                      <div className="border-prime-800 border-b-[1px]"></div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="logo lg:w-[250px] w-[200px]">
            <img
              src={logo1}
              alt="Researcher Circle"
              onClick={() => {
                navigate("/home");
              }}
              className=" lg:pl-[15px] pl-[0px] cursor-pointer"
            />
          </div>
          <div className="navigation lg:flex items-center hidden">
            {pages.map((page) => (
              <div
                key={page.name}
                onClick={() => {
                  if (!page.subNav) {
                    navigate(page.navLink);
                    hideAllNavigation();
                  } else {
                    showHideSubNavigation(page);
                  }
                }}
                className="navLinks px-[10px]"
              >
                <div>
                  <div className="cursor-pointer relative">
                    {page.name}
                    {page.subNav && (
                      <SvgIcon
                        className=""
                        component={KeyboardArrowDownIcon}
                        onClick={() => {}}
                      />
                    )}
                  </div>
                  {page.showSubNav && (
                    <div className="absolute shadow-md min-w-[170px] bg-prime-200 py-[10px] px-[15px] mt-[5px] border-prime-800 border-[0.1px] rounded z-10">
                      {page.subNav?.map((nav, index) => (
                        <div key={nav.id}>
                          <div
                            className="flex justify-between py-[10px] cursor-pointer align-middle"
                            onClick={() => {
                              navigate(nav.navLink);
                            }}
                          >
                            {nav.name}
                            <SvgIcon
                              className="ml-[15px]"
                              component={ArrowRightAltIcon}
                            />
                          </div>
                          {index < page.subNav.length - 1 && (
                            <div className="border-prime-800 border-b-[1px]"></div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className="loginBtn flex flex-col pr-[15px] cursor-pointer"
          onClick={() => {
            navigate("/Login");
          }}
        >
          <SvgIcon className="" component={Login} />
          <label className="text-[12px] cursor-pointer">Login</label>
        </div>
      </div>
    </>
  );
}

export default Header;
