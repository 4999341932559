function ContactForm() {
  return (
    <>
      <div className="flex justify-center flex-col mb-[40px] mt-[30px] mx-4 md:mx-0">
        <form className="w-full max-w-xl bg-white rounded-lg shadow-md p-6 border-gray-300 border-[0.1px]">
          <div className="pb-[30px]">
            <span className="text-sm">for any quires and information</span>
            <h2 className="text-3xl tracking-tight text-gray-500">
              Get in touch with us
            </h2>
          </div>
          <div className="flex flex-wrap mb-6">
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Name
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                type="email"
                required
              />
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Email address
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                type="email"
                required
              />
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Phone No
              </label>
              <input
                className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                type="tel"
                required
              />
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <label className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2">
                Message
              </label>
              <textarea
                className="appearance-none block w-full min-h-[130px] bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none"
                required
              />
            </div>
            <div className="w-full md:w-full px-3 mb-6">
              <button className="appearance-none block w-full bg-blue-600 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-blue-500 focus:outline-none focus:bg-white focus:border-gray-500">
                Send
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default ContactForm;
