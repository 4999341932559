import UnderConstruction from "../UnderConstruction/UnderConstruction";

function Books() {
  console.log("books");

  return (
    <>
      <UnderConstruction />
    </>
  );
}

export default Books;
