import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import { IJournal, journaldetails } from "../../pages/Journals/Journals.config";

function JournalsDetails() {
  const journalRoute = String(useOutletContext());
  const [selecteTab, setSelecteTab] = useState(1);
  const [journal, setJournal] = useState<IJournal | null>(null);

  useEffect(() => {
    journaldetails.forEach((item) => {
      if (item.navLink.split("/")[1] == journalRoute) {
        setJournal(item);
      }
    });
  }, [journalRoute]);

  return (
    <div className="text-prime-800">
      <div className="py-[80px] px-[60px] bg-prime-300">
        <p>RC International Journal Of</p>
        <h1 className="text-4xl font-bold pb-[5px]">{journal?.name}</h1>
        <p>Current Vol. : 1 No. 1 (2024)</p>
      </div>
      <div className="flex lg:items-start items-center justify-center lg:py-[80px] py-[40px] lg:px-[60px] px-[30px] lg:flex-row flex-col">
        <div className="flex justify-center lg:basis-[40%] basis-[100%] pb-[30px]">
          <div className="lg:w-[350px] w-[250px] bg-prime-600 rounded-md border-[2px] border-prime-800 shadow-lg">
            <img src={journal?.cover} className="rounded-md" />
          </div>
        </div>
        <div className="flex justify-start flex-col lg:basis-[60%] basis-[100%]">
          <div className="pb-[30px] ">
            <p>RC International Journal of</p>
            <h1 className="text-3xl pb-[10px] font-bold">{journal?.name}</h1>
            <p>{journal?.description}</p>
          </div>
          <div className=" pb=[30px]">
            <div>
              <span className="font-bold">Title :&nbsp;</span>
              <span>{journal?.title}</span>
            </div>

            <div>
              <span className="font-bold">Frequency :&nbsp;</span>
              <span>{journal?.frequency}</span>
            </div>

            <div>
              <span className="font-bold">e-ISSN :&nbsp;</span>
              <span>{journal?.eISSN}</span>
            </div>

            <div>
              <span className="font-bold">Publisher :&nbsp;</span>
              <span>{journal?.publisher}</span>
            </div>

            <div>
              <span className="font-bold">Chief Editor :&nbsp;</span>
              <span>{journal?.chiefEditor}</span>
            </div>

            <div>
              <span className="font-bold">Copyright :&nbsp;</span>
              <span>{journal?.copyright}</span>
            </div>

            <div>
              <span className="font-bold">Starting year :&nbsp;</span>
              <span>{journal?.startingYear}</span>
            </div>

            <div>
              <span className="font-bold">Subject :&nbsp;</span>
              <span>{journal?.subject}</span>
            </div>

            <div>
              <span className="font-bold">Language :&nbsp;</span>
              <span>{journal?.language}</span>
            </div>

            <div>
              <span className="font-bold">Publication format :&nbsp;</span>
              <span>{journal?.publicationFormat}</span>
            </div>

            <div>
              <span className="font-bold">Email Id :&nbsp;</span>
              <span>{journal?.emailId}</span>
            </div>

            <div>
              <span className="font-bold">Mobile No. :&nbsp;</span>
              <span>{journal?.mobileNo}</span>
            </div>

            <div>
              <span className="font-bold">Readership :&nbsp;</span>
              <span>{journal?.readership}</span>
            </div>

            <div>
              <span className="font-bold">Indexed in :&nbsp;</span>
              <span>{journal?.indexedIn}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-[30px] lg:flex justify-center border-b-[2px]">
        <div className="flex justify-center px-[30px] ">
          <label
            className={`p-[30px] cursor-pointer ${
              selecteTab == 1 ? "border-b-[6px]" : ""
            } `}
            onClick={() => {
              setSelecteTab(1);
            }}>
            Publications
          </label>
          <label
            className={`p-[30px] cursor-pointer ${
              selecteTab == 2 ? "border-b-[6px]" : ""
            } `}
            onClick={() => {
              setSelecteTab(2);
            }}>
            Editorial Team
          </label>
          <label
            className={`p-[30px] cursor-pointer ${
              selecteTab == 3 ? "border-b-[6px]" : ""
            } `}
            onClick={() => {
              setSelecteTab(3)
            }}>
            Author Guidelines
          </label>
          <label
            className={`p-[30px] cursor-pointer ${
              selecteTab == 4 ? "border-b-[6px]" : ""
            } `}
            onClick={() => {
              setSelecteTab(4)
            }}>
            Editorial Guidelines
          </label>
        </div>
      </div>
      {/* publications */}
      {
        (selecteTab == 1) && 
          <div>
            Publications
          </div>
      }
      {/*  Editorial Team */}
      {
        (selecteTab == 2) && 
          <div>
            Editorial Team
          </div>
      }
      {/* Author Guidelines */}
      {
        (selecteTab == 3) && 
          <div>
            Author Guidelines
          </div>
      }
      {/* Editorial Guidelines */}
      {
        (selecteTab == 4) && 
          <div>
            Editorial Guidelines
          </div>
      }
    </div>
  );
}

export default JournalsDetails;
